<template>
  <div class="pt-5 applications">
    <v-container 
      class="mt-0 pb-0 px-6 applications-body"
    >
      <v-row class="px-4 py-2">
        <h1>
          Applications
        </h1>
        <v-spacer />
        <v-btn
          class="mb-3"
          depressed
          color="accent"
          @click="createApplicationDialog = true"
        >
          <v-icon 
            small 
            class="pr-3"
          >
            mdi-plus
          </v-icon>
          New Application
        </v-btn>

        <v-dialog
          v-model="createApplicationDialog"
          max-width="600px"
        >
          <v-card> 
            <v-card-title>
              New Application
            </v-card-title>
            <v-text-field
              label="Application Name"
              v-model="newApplication.name"
              class="px-6"
            />
            <v-card-actions class="px-4 pb-4">
              <v-spacer />
              <v-btn
                outlined
                @click="createApplicationDialog = false, newApplication.name = undefined"
              >
                Cancel
              </v-btn>
              <v-btn
                elevation="0"
                color="accent"
                @click="createApplication()"
              >
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-divider />
      <v-sheet
        class="mx-auto"
        elevation="0"
      >
        <v-slide-group
          v-model="selectedApplication"
          class="py-4"
          show-arrows
          center-active
        >
          <v-slide-item
            v-for="application in applications"
            :key="application.name"
            v-slot:default="{ active, toggle }"
          >
            <v-card
              :color="active ? 'accent' : 'grey lighten-4'"
              class="py-2 px-8 mx-1"
              elevation="0"
              @click="toggle"
            >
              <v-row
                class="fill-height"
                align="center"
                justify="center"
              >
                <v-scale-transition>
                  <h5
                    v-if="active"
                    style="color: white"
                  >
                    {{ application.name }}
                  </h5>
                  
                  <h5 v-else>
                    {{ application.name }}
                  </h5>
                </v-scale-transition>
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>

      <!-- Applications Loader/Divider -->
      <v-row 
        v-if="isLoading"
        class="mx-auto pb-10"
        align="center"
        justify="center"
      >
        <v-progress-circular indeterminate />
      </v-row>

      <v-divider v-else />
  
      <Application
        v-if="selectedApplication !== undefined" 
        :application="applications[selectedApplication]"
        :isAdmin="this.isAdmin"
        :isSupport="this.isSupport"
      />
    </v-container>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import Application from "@/router/components/Application"

const getPersonApplicationsQuery = `query ($owner: String, $nextToken: String) {
  listElements (
    filter: {
      owner: {
        eq: $owner
      }
    }
    nextToken: $nextToken
  ) {
    items {
      id
      alias
      application
      approved
    }
    nextToken
  }
}`

const getAllApplications = `query ($nextToken: String) {
  listElements(
    nextToken: $nextToken
  ) {
    items {
      id
      alias
      application
      viewers
      approved
    }
    nextToken
  }
}`

const getPersonGroups = `query ($lookup_id: String!) {
  personByNetID(net_id: $lookup_id) {
    items {
      attributes
    }
  }
}`

export default {
  name: "Applications",
  components: {
    Application
  },
  data () {
    return {
      // Application & Elements
      applications: [],
      selectedApplication: undefined,
      createApplicationDialog: false,
      newApplication: {
        "name": undefined,
        "elements": []
      },
      
      // Auth Check
      isAdmin: false,
      isSupport: false,
      isLoading: false,
    }
  },

  created () {
    this.checkAdmin()
    this.getApplications()
  },

  methods: {
    createApplication() {
      let applicationIndex = this.applications.push(this.newApplication) - 1
      this.newApplication = {
        "name": undefined,
        "elements": []
      }
      this.createApplicationDialog = false
      this.selectedApplication = applicationIndex
    },
    async checkAdmin() {
      await API.graphql({
        query: getPersonGroups, 
        variables: {lookup_id: this.$store.state.user.attributes.name.split('@')[0]}
      })
        .then(data => {
          let attributes = data.data.personByNetID.items[0].attributes
          this.isAdmin = attributes.includes('provisioning_admins')
          this.isSupport = attributes.includes('provisioning_semi_admin')
        })
        .catch(error => {
          console.log(error)
        })
    },

    // Get Elements
    async getApplications() {
      this.isLoading = true;
      let applicationQuery = null;
      let lookupVariables = null;
      let elements = [];
      let nextToken = 'nullToken';

      var groupBy = function (elements, application) {
        const applications = [];
        
        elements.forEach((element) => {
            const key = application(element);
            const applicationIndex = applications.findIndex(application => {
              return application.name === key
            });

            if (element.attributes === null || element.attributes === undefined) {
              element.attributes = []
            }

            if (element.viewers === null || element.viewers === undefined) {
              element.viewers = []
            }

            if (applicationIndex === -1) {
              applications.push({
                name: key,
                elements: [element]
              });
            } else {
              applications[applicationIndex].elements.push(element);
            }
        });

        return applications;
      }


      while (nextToken !== null) {
        if (nextToken === 'nullToken') {
          nextToken = null;
        }

        if (this.isAdmin || this.isSupport) {
          applicationQuery = getAllApplications
          lookupVariables = {nextToken: nextToken}
        } else {
          applicationQuery = getPersonApplicationsQuery
          lookupVariables = {
            owner: this.$store.state.user.attributes.name.split('@')[0],
            nextToken: nextToken
          }
        }

        await API.graphql(graphqlOperation(applicationQuery, lookupVariables))
        .then(data => {
          nextToken = data.data.listElements.nextToken
          elements.push(...data.data.listElements.items)
        })
        .catch(error => {
          console.log(error)
        })
      }

      this.applications = groupBy(elements, element => element.application)

      nextToken = 'nullToken'
      this.isLoading = false;
      console.log(this.applications)

    }
  }
}
</script>

<style>
  .access {
    width: 1.5em;
    background-color: #10A170;
  }

  .pending {
    width: 1.5em;
    background-color: #2196F3;
    word-wrap: break-word;
  }

  .scheduled {
    width: 1.5em;
    background-color: #FFB700;
  }

  .error {
    width: 1.5em;
    background-color: #A3082A;
  }

  .applications {
    background-color: #F5F5F5;
    height: 100%;
  }

  .applications-body {
    background-color: #ffffff;
    height: 97%;
    overflow-y: auto;
    padding-top: 24px;
  }

  h1 {
    font-size: 24px;
  }

  p {
    padding: 0;
    margin: 0;
  }
  .name {
    font-size: 22px;
    font-weight: lighter;
  }

  .details {
    font-size: 16px;
    font-weight: 300
  }

  h5 {
    font-size: 1.2em;
    font-weight: lighter;
  }

  .v-card__text, .v-card__title {
    word-break: normal !important; 
  }
  
</style>