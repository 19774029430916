<template>
  <v-card 
    light
    class="pa-4"
  >
    <v-card-title class="pa-2">
      Edit Emails
      <v-spacer />
      <v-select
        outlined
        v-model="selectedEmail" 
        :items="emailNames" 
        style="max-width: 200px"
        hide-details
        dense
        class="py-0 mt-0"
      />
    </v-card-title>
    <v-divider class="my-2 mx-2" />
    <v-card-text class="py-0 px-2">
      <v-row class="px-3">
        <v-col cols="8">
          <div class="header">
            <span 
              slot="site-title" 
              style="color:white"
            >
              BYU  |  Provisioning
            </span>
          </div>
          <tiptap-vuetify
            v-model="elementData[selectedEmail]"
            :extensions="extensions"
          />
        </v-col>
        <v-col>
          <v-card outlined>
            <v-card-title class="pb-0">
              Variables
            </v-card-title>
            <v-divider class="mx-3 mt-2" />
            <v-list-item 
              v-for="variable in variables" 
              :key="variable.name"
            >
              <v-list-item-content class="py-2">
                <v-list-item-title>
                  <b>${{ variable.name }}</b>
                </v-list-item-title>
                {{ variable.text }}
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        @click="cancelUpdate()"
      >
        Cancel
      </v-btn>
      <v-btn
        elevation="0"
        color="accent"
        @click="updateElement()"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  // component
  TiptapVuetify,
  // extensions
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";


export default { 
  components: { TiptapVuetify },
	props: [
    "element"
  ],
	data () {
		return {
      elementData: this.element,
      previousElement: {...this.element},
      emailNames: [
        { "value": "provision_email", "text": "Provisioning" },
        { "value": "deprovision_email", "text": "Deprovisioning"},
        { "value": "schedule_email", "text": "Schedule"}
      ],
      selectedEmail: "provision_email",
      test: "",
      extensions: [
        History,
        Paragraph,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Italic,
        Underline,
        Strike,
        Blockquote,
        Link,
        ListItem,
        BulletList,
        OrderedList,
        Code,
        HorizontalRule,
        HardBreak,
      ],
      variables: [
        { "name": "netId", "text": "NetID of the recipient"},
        { "name": "byuId", "text": "BYU ID of the recipient"},
        { "name": "firstName", "text": "First name of the recipient"},
        { "name": "lastName", "text": "Last name of the recipient"},
        { "name": "alias", "text": "Alias of provisioning element that has changed"},
        { "name": "scheduledDate", "text": "The date for scheduled removal"}
      ]
		}
  },
  computed: {
 

  },
  methods: {
    updateElement() {
      console.log(this.elementData)
      this.$emit('setElement', this.elementData)
    },

    // Assign element to element prior to update
    cancelUpdate () {
      this.elementData = this.previousElement;  
      this.$emit('cancelEdit', this.previousElement)
    }
  }
}
</script>

<style>

p {
  font-size: 18px;
  color: #000;
}

.header {
	background-color: 4183C4;
	background-color: rgb(32, 54, 92);
    width: 100%;
    height: 60px;
    line-height: 60px;
    padding: 0 2% 0;
	visibility: visible;
	font-size: 23px;
  font-family: 'HCo Ringside Narrow SSm', 'Open Sans', Helvetica, Arial, sans-serif;
}


</style>