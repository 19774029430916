<template>
  <v-card elevation="0">
    <v-card-title>
      <v-row class="title">
        <h1>
          {{ selectedElement.alias }}
        </h1>
        <v-spacer />

        <v-tooltip 
          bottom

        >
          <template v-slot:activator="{ on }">
            <v-btn
              elevation="0"
              height="40"
              rounded
              v-on="on"
              :disabled="!isAdmin"
              @click="selectedElement.approved = !selectedElement.approved, updateElement(selectedElement)"
              class="mx-2"
            >
              <h6
                v-if="selectedElement.approved">
                Approved
              </h6>
              <h6
                class="yellow--text text--darken-2"
                v-else>
                Pending Approval
              </h6>
              <v-icon
                right
                v-if="selectedElement.approved && isAdmin"
              >
                mdi-checkbox-marked-outline
              </v-icon>
              <v-icon
                right
                color="yellow darken-2"
                v-else-if="isAdmin"
              >
                mdi-checkbox-blank-outline
              </v-icon>
            </v-btn>
          </template>
          <span v-if="selectedElement.approved">Remove Approval</span>
          <span v-else>Approve</span>
        </v-tooltip>


        <v-tooltip
          bottom
          v-if="isAdmin || this.$store.state.user.attributes.name.split('@')[0] == this.selectedElement.owner"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              elevation="0"
              small
              @click.stop="logDialog = true"
              class="mx-2"
            >
              <v-icon v-on="on">
                mdi-list-status
              </v-icon>
            </v-btn>
          </template>
          <span>Logs</span>
        </v-tooltip>

        <v-dialog
          v-model="logDialog"
          max-width="1000px"
        >
          <v-card 
            elevation="0" 
            class="pa-4"
          > 
            <v-card-title class="pt-0">
              Logs
              <v-spacer />
              <v-text-field
                v-if="isAdmin || isSupport"
                placeholder="BYU ID"
                solo
                single-line
                dense
                outlined
                flat
                hide-details
                clearable
                v-model="lookupID"
                class="py-4"
                style="max-width: 250px"
              />
            </v-card-title>
            <v-divider class="mx-4 mt-4" />
            <v-card 
              elevation="0" 
              class="px-4"
            >
              <v-data-table v-if="selectedElement.logs"
                :headers="logHeaders"
                :items="selectedElement.logs.items"
                :search="lookupID"
                :items-per-page="10"
              >
                <template
                  v-slot:[`item.createdAt`]="{ item }"
                >
                  {{ new Date(item.createdAt).toLocaleString() }}
                </template>                          
              </v-data-table>
            </v-card>
          </v-card>
        </v-dialog>

        <v-tooltip
          bottom
          v-if="isAdmin || this.$store.state.user.attributes.name.split('@')[0] == this.selectedElement.owner"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              elevation="0"
              small
              @click.stop="deleteDialog = true"
              class="mx-2"
            >
              <v-icon v-on="on">
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>

        <v-dialog
          v-model="deleteDialog"
          max-width="700"
        >
          <v-card class="pa-2">
            <v-card-title><v-spacer />Are you sure you would like to delete {{ selectedElement.alias }}?<v-spacer /></v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                @click="deleteDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="error"
                outlined
                @click="deleteElement(selectedElement)"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="deleteErrorDialog"
          max-width="700"
        >
          <v-card class="pa-2">
            <v-card-title><v-spacer />Cannot delete {{ selectedElement.alias }}<v-spacer /></v-card-title>
            <v-card-text class = text-center>
              This element still has valid connections. Please remove associated groups to remove all connections before deleting.
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              
              <v-btn
                text
                @click="deleteErrorDialog = false"
              >
                Okay
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card-title>
    <v-row class="fill-height overflow-y-auto pt-0">
      <v-col
        xl="8"
        md="7"
        sm="12"
      >
        <v-card 
          flat 
          outlined
        >
          <v-card-title class="pb-0">
            Details
            <v-spacer />

            <!-- <v-tooltip 
              bottom
              v-if="isAdmin || isOwner"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="isAdmin"
                  fab
                  elevation="0"
                  small
                  :disabled="!!selectedElement.subscription_arn"
                  @click="resendSubscriptionEvent()"
                  class="mx-2"
                >
                  <v-icon 
                    v-if="!selectedElement.subscription_arn" 
                    v-on="on"
                  >
                    mdi-database-refresh
                  </v-icon>

                  <v-icon v-else>
                    mdi-database-check
                  </v-icon>
                </v-btn>
              </template>
              <span>Resend Subscription Event</span>
            </v-tooltip> -->

            <v-tooltip
              bottom
              v-if="isAdmin || this.$store.state.user.attributes.name.split('@')[0] == this.selectedElement.owner"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  elevation="0"
                  small
                  @click.stop="editDialog = true"
                  class="mx-2"
                >
                  <v-icon v-on="on">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-dialog
              v-model="editDialog"
              max-width="600px"
            >
              <EditElement 
                title="Edit"
                :element="{...selectedElement}"
                :isAdmin="this.isAdmin"
                @setElement="updateElement($event), editDialog = false"
                @cancelEdit="editDialog = false"
              />
            </v-dialog>
          </v-card-title>
          <v-card-text>
            <v-list-item
              two-line
              class="pa-0"
            >
              <v-list-item-content>
                <v-list-item-subtitle>Consumer Name</v-list-item-subtitle>
                <v-list-item-title v-if="selectedElement.name">
                  {{ selectedElement.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            
            <v-list-item
              two-line
              class="pa-0"
            >
              <v-list-item-content>
                <v-list-item-subtitle>Provision Endpoint</v-list-item-subtitle>
                <v-list-item-title v-if="selectedElement.endpoint">
                  {{ selectedElement.endpoint }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-row>
              <v-col class="py-0">
                <v-list-item 
                  two-line
                  class="pa-0"
                >
                  <v-list-item-content>
                    <v-list-item-subtitle>Scheduled Removal Time</v-list-item-subtitle>
                    <v-list-item-title>{{ selectedElement.removal_time }} Days</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col class="py-0">
                <v-list-item 
                  v-if="selectedElement.owner"
                  two-line
                  class="pa-0"
                >
                  <v-list-item-content>
                    <v-list-item-subtitle>Owner</v-list-item-subtitle>
                    <v-list-item-title>
                      {{ selectedElement.owner }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-list-item
              v-if="selectedElement.byu_api_client_id"
              two-line
              class="pa-0"
            >
              <v-list-item-content>
                <v-list-item-subtitle>BYU API Client ID</v-list-item-subtitle>
                <v-list-item-title>
                  {{ selectedElement.byu_api_client_id }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>

        <v-card 
          flat 
          outlined
          class="mt-4"
        >
          <v-card-title class="pb-0">
            Emails
            <v-spacer />

            <v-tooltip
              bottom
              v-if="isAdmin || this.$store.state.user.attributes.name.split('@')[0] == this.selectedElement.owner"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  elevation="0"
                  small
                  @click.stop="emailDialog = true"
                  class="mx-2"
                >
                  <v-icon 
                    v-if="!selectedElement.provision_email && !selectedElement.schedule_email && !selectedElement.deprovision_email"
                    v-on="on"
                  >
                    mdi-plus
                  </v-icon>
                  <v-icon
                    v-else
                  >
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-dialog
              v-model="emailDialog"
              max-width="1200px"
            >
              <EditEmail 
                :element="{...selectedElement}"
                @setElement="updateElement($event), emailDialog = false"
                @cancelEdit="emailDialog = false"
              />
            </v-dialog>
          </v-card-title>
          <v-card-text v-if="!selectedElement.provision_email && !selectedElement.schedule_email && !selectedElement.deprovision_email">
            <p class="missing-data pt-4">
              No Emails Added
            </p>
          </v-card-text>

          <v-card-text v-else>
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-if="selectedElement.provision_email">
                  mdi-email-check
                </v-icon>
                <v-icon v-else>
                  mdi-email-off
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Provision Email
              </v-list-item-title>
              <v-list-item-action v-if="selectedElement.provision_email">
                <v-btn 
                  icon
                  @click="selectedElement.provision_email = null"
                  v-if="isAdmin || this.$store.state.user.attributes.name.split('@')[0] == this.selectedElement.owner"
                >
                  <v-icon small color="grey lighten-1">mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-if="selectedElement.deprovision_email">
                  mdi-email-check
                </v-icon>
                <v-icon v-else>
                  mdi-email-off
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Deprovision Email
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="selectedElement.deprovision_email">
                <v-btn 
                  icon
                  @click="selectedElement.deprovision_email = null"
                  v-if="isAdmin || this.$store.state.user.attributes.name.split('@')[0] == this.selectedElement.owner"
                >
                  <v-icon small color="grey lighten-1">mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-if="selectedElement.schedule_email">
                  mdi-email-check
                </v-icon>
                <v-icon v-else>
                  mdi-email-off
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Scheduled Removal Email
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="selectedElement.schedule_email">
                <v-btn 
                  icon
                  @click="selectedElement.schedule_email = null"
                  v-if="isAdmin || this.$store.state.user.attributes.name.split('@')[0] == this.selectedElement.owner"
                >
                  <v-icon small color="grey lighten-1">mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card 
          flat 
          outlined
        >
          <v-card-title>
            Groups
            <v-spacer />
          </v-card-title>
          <v-sheet 
            class="px-4 pb-4"
          >
            <v-combobox
              v-if="isAdmin || this.$store.state.user.attributes.name.split('@')[0] == this.selectedElement.owner"
              class="py-2 mb-4"
              hint="GRO Groups used to determine who will be provisioned (Press enter after each group)"
              v-model="selectedElement.attributes"
              :label="((!selectedElement.attributes) || (selectedElement.attributes.length == 0)) ? 'No Groups Added': ''"
              multiple
              chips
              deletable-chips
              allow-overflow
              persistent-hint
              @change="updateElement(selectedElement)"
            />
            <v-combobox
              v-else
              v-model="selectedElement.attributes"
              multiple
              chips
              allow-overflow
              disabled
              persistent-hint
              hint="Anyone is these GRO Groups will be provisioned for this appliation"
            />
          </v-sheet>
        </v-card>


        <!-- <v-card 
          flat 
          outlined
          class="mt-4"
        >
          <v-card-title>
            Read Access
            <v-spacer />
          </v-card-title>
          <v-sheet 
            class="px-4 pb-4" 
          >
            <v-combobox
              class="py-2 mb-4"
              hint="NetID's used to give read access to this element (Press enter after each NetID)"
              v-model="selectedElement.viewers"
              :label="!selectedElement.viewers ? 'No Viewers Added': ''"
              multiple
              chips
              deletable-chips
              persistent-hint
              @change="updateElement(selectedElement)"
            />
          </v-sheet>
        </v-card> -->
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import EditElement from "@/router/components/EditElement";
import EditEmail from "@/router/components/EditEmail";

const updateElementMutation = `mutation (
    $updateElement: UpdateElementInput! 
  ) {
  updateElement(input: $updateElement) {
    id
    removal_time
    approved
  }
}`

const getElementQuery = `query ($elementID: ID!) {
  getElement(
    id: $elementID
  ) {
    id
    alias
    name
    removal_time
    application
    byu_api_client_id
    endpoint
    attributes
    owner
    viewers
    approved
    provision_email 
    deprovision_email 
    schedule_email
    persons (limit: 1){
      items {
        person {
          net_id
        }
      }
    }
    logs (limit: 10) {
      items {
        byu_id
        action
        createdAt
        type
      }
    }
  }
}`

const getLogQuery = `query ($elementID: ID!, $nextToken: String) {
  getElement(id: $elementID){
    alias
    logs (limit: 100000, nextToken: $nextToken) {
      items {
        byu_id
        action
        createdAt
        type
      }
      nextToken
    }
  }
}`

const getPersonGroups = `query ($lookup_id: String!) {
  personByNetID(net_id: $lookup_id) {
    items {
      attributes
    }
  }
}`


export default {
  name: "Element",
  props: {
    isAdmin: Boolean,
    isSupport: Boolean,
    "element": {
      type: Object,
      default () {
        return {
          approved: false,
          name: '',
          alias: '',
          application: '',
          removal_time: 0,
          attributes: [],
          viewers: [],
          endpoint: '',
          provision_email: undefined,
          deprovision_email: undefined,
          schedule_email: undefined,
          byu_api_client_id: '',
        }
      }
    }
  },
  components: {
    EditElement,
    EditEmail
  },
  data () {
    return {
      selectedElement: this.element,
    
      // Dialogs
      createDialog: false,
      deleteDialog: false,
      deleteErrorDialog: false,
      editDialog: false,
      emailDialog: false,
      logDialog: false,
      editAttributes: false,
      editViewers:  false,

      // Logs
      lookupID: '',
      loadingLookup: false,
      logHeaders: [
        { text: 'BYU ID', align: 'start', value: 'byu_id'},
        { text: 'Status', align: 'start', value: 'type'},
        { text: 'Action', align: 'start', value: 'action'},
        { text: 'Date', align: 'start', value: 'createdAt'}
      ],

      // Auth Check
      // isViewer: true,
      // isAdmin: false,
      isLoading: false
    }
  },

  created () {
    this.getElement(this.element)
    // this.checkAdmin()
  },

  watch: {
    element: function () {
      this.getElement(this.element)
    }
  },
  

  methods: {
    async checkAdmin() {
      await API.graphql({
        query: getPersonGroups, 
        variables: {lookup_id: this.$store.state.user.attributes.name.split('@')[0]}
      })
        .then(data => {
          let attributes = data.data.personByNetID.items[0].attributes
          this.isAdmin = attributes.includes('provisioning_admins')
          this.isViewer = !this.isAdmin
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getElement(element) {
      await API.graphql(graphqlOperation(
        getElementQuery, {'elementID': element.id}
      ))
      .then(response => {
        this.selectedElement = response.data.getElement
        this.getLogs()
      })
      .catch(error => {
        console.log(error)
      })
    },

    async getLogs() {
      let nextToken = 'nullToken';
      let logList = [];

      while (nextToken !== null) {
        if (nextToken === 'nullToken') {
          nextToken = null;
        }
        await API.graphql(graphqlOperation(
          getLogQuery, 
          {
            'elementID': this.element.id,
            'nextToken': nextToken
          }
        ))
        .then(response => {
          logList = logList.concat(response.data.getElement.logs.items)
          nextToken = response.data.getElement.logs.nextToken
          this.selectedElement.logs.items = logList
        })
        .catch(error => {
          console.log(error)
        })
      }

      nextToken = 'nullToken'
    },

    // Update Element
    async updateElement(element) {
      delete element['logs']
      delete element['persons']
      await API.graphql(graphqlOperation(
        updateElementMutation, {'updateElement': element}
      ))
      .then(() => {
        this.$emit('updatedElement', element)
        this.selectedElement = element
      })
      .catch(error => { 
        console.log(error)
        this.selectedElement = element
      })
    },
  
    deleteElement(element) {
      if (element['persons']['items'].length == 0){
        console.log('Deleting Element')
        this.$emit('deleteElement', element.id)
        this.deleteDialog = false
      }
      else {
        this.deleteDialog = false
        this.deleteErrorDialog = true
      }
    }
  }
}
</script>

<style>
.buttons {
  justify-content: flex-end;
}

.title {
  justify-content: flex-start;
}

.missing-data {
  font-weight: lighter;
  font-size: 24px;
  color: #CCCCCC;
}

@media only screen and (max-width: 600px) {
  .buttons {
    justify-content: center;
  }
  .title {
    justify-content: center;
    text-align: center;
  }

}
</style>